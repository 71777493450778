import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { adminRoute } from "..";

export const claimsRoute = createRoute({
  path: "claims",
  getParentRoute: () => adminRoute,
});

export const claimsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => claimsRoute,
  component: lazyRouteComponent(() => import("./+Claims")),
});
